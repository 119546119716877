import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '../../core/services/util.service';

@Component({
  selector: 'app-repair-result',
  templateUrl: './repair-result.component.html',
  styleUrls: ['./repair-result.component.scss'],
})
export class RepairResultComponent implements OnInit {
  repairSuccess: number;
  repairMsg = '';
  title = '';
  selectedAction;
  parts = [];

  constructor(
    private utilService: UtilService,
    private router: Router,
    public translate: TranslateService
  ) {
    this.translate.stream('DASHBOARD.PARTS').subscribe((res) => {
      this.parts = res;
      this.translateText();
    });
  }

  ngOnInit() {
    this.utilService.checkIfLoggedIn();

    this.init();
  }

  translateText() {
    this.selectedAction = this.utilService.getAction();
    if (
      this.selectedAction !== null &&
      this.selectedAction !== undefined &&
      this.selectedAction.hasOwnProperty('key')
    ) {
      this.title = this.parts.filter(
        (obj) => obj.key.toLowerCase() === this.selectedAction.key.toLowerCase()
      )[0].value;
      // this.title = this.selectedAction.value;
    }
  }

  init() {
    this.repairMsg = localStorage.getItem('repairMsg');
    const result = localStorage.getItem('repairResult');

    if (result && result !== undefined && result !== '' && result === 'true') {
      this.repairSuccess = 1;
    } else {
      this.repairSuccess = 0;
    }
  }

  next() {
    // clear out localstorage
    this.utilService.clearSavedData();

    this.router.navigate(['/dashboard']);
  }
}
