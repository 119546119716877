import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  errorMessage = '';
  hide = true;

  constructor(public fb: FormBuilder, private router: Router) {}

  ngOnInit() {
    // localStorage.setItem('smtLogin', '0');
    this.createForm();
  }

  createForm() {
    this.resetForm = new FormGroup({
      newPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        ),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        ),
      ]),
    });
  }

  resetFormSubmit() {
    const newPassword = this.resetForm.get('newPassword').value;
    const confirmPassword = this.resetForm.get('confirmPassword').value;
    // if (
    //   this.resetForm.valid &&
    //   newPassword !== '' &&
    //   confirmPassword !== '' &&
    //   newPassword !== confirmPassword
    // ) {
    //   this.errorMessage = 'Passwords do not match.';
    // } else {
    //   this.errorMessage = '';
    // }
  }

  // reset form input field validation
  hasError(controlName: string, errorName: string) {
    return this.resetForm.controls[controlName].hasError(errorName);
  }
}
