import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat, Result } from '@zxing/library';
import { UtilService } from '../../core/services/util.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-repair-scan-new-part',
  templateUrl: './repair-scan-new-part.component.html',
  styleUrls: ['./repair-scan-new-part.component.scss'],
})
export class RepairScanNewPartComponent implements OnInit {
  @ViewChild('scanner') scanner: ZXingScannerComponent;
  @ViewChild('serialNoInput') serialNoInput: ElementRef;
  title;
  hasDevices = false;
  hasPermission: boolean;
  qrResult: Result;
  availableDevices: MediaDeviceInfo[] = [];
  currentDevice: MediaDeviceInfo = null;
  displayQRCodeError = false;
  displayQRCodeMsg = '';
  qrResultString: any;
  serialNo = '';
  isDisable = true;
  desc = '';
  placeholder = '';
  allowedFormats = [];
  selectedAction;
  obj;
  pattern = /^[0-9]*$/;
  tryHarder = true;
  typeOfCode = '';
  backCameraList = [];
  cameras;
  selected = null;
  autoStart;
  showScannerDiv = true;
  parts = [];
  key = '';
  subtitle = '';
  placeholderText = '';

  constructor(
    private utilService: UtilService,
    private router: Router,
    private zone: NgZone,
    public translate: TranslateService
  ) {
    // tslint:disable-next-line: no-string-literal
    window['angularComponentReference'] = {
      zone: this.zone,
      componentFn: (searchcontent: any) =>
        // tslint:disable-next-line: no-string-literal
        window['scannerOutput'](searchcontent),
      component: this,
    };

    this.translate
      .stream([
        'DASHBOARD.PARTS',
        'REPLACEMENT_PART.SUBTITLE',
        'REPLACEMENT_PART.SCAN_PLACEHOLDER_TEXT',
      ])
      .subscribe((res) => {
        // console.log(res);
        this.parts = res['DASHBOARD.PARTS'];
        this.subtitle = res['REPLACEMENT_PART.SUBTITLE'];
        this.placeholderText = res['REPLACEMENT_PART.SCAN_PLACEHOLDER_TEXT'];
        this.init();
      });
  }

  ngOnInit() {
    this.utilService.checkIfLoggedIn();

    // to remove expressionchanged error
    // https://github.com/zxing-js/ngx-scanner/issues/270
    this.scanner.reset = function () {
      setTimeout(() => {
        this.deviceChange.emit(null);
      });
    };

    // this.init();
  }

  // In this method, we are trying to get the back cameras available on the mobile device on which this application is served. The library ngx-scanner by default gives us all the audio and video devices available via the cameraDevices parameter. But it is observed that in some mobile devices like Samsung, this doesn't work so we need to use the navigator.enumeratedevices API and get the devices.
  // From the devices available, we are filtering out the back-cameras only since the scanning will be carried out using the back-cameras. We are also trying to set the first camera as the default camera. This is done because in Samsung, by default the wide-lens camera is set as default and scanning doesnt work using the same!
  async onCamerasFound(cameraDevices: MediaDeviceInfo[]) {
    let cameraDeviceList: MediaDeviceInfo[] = [];

    // filter out video only
    this.cameras = cameraDevices.filter(
      (device) => device.kind === 'videoinput'
    );

    // filter out only back cameras
    this.cameras = this.cameras.filter((camera) =>
      this.utilService.isBackCameraLabel(camera.label)
    );

    if (this.cameras.length > 0) {
      cameraDeviceList = this.cameras.slice();
    } else {
      let devices = await navigator.mediaDevices.enumerateDevices();
      let cameras = devices.filter((device) => device.kind === 'videoinput');
      cameras = cameras.filter((camera) =>
        this.utilService.isBackCameraLabel(camera.label)
      );
      cameraDeviceList = cameras.slice();
    }

    this.hasDevices = Boolean(cameraDeviceList && cameraDeviceList.length);

    this.availableDevices = cameraDeviceList.slice(); // this.cameras.slice();

    this.availableDevices = this.availableDevices.sort((camera1, camera2) =>
      camera1.label.localeCompare(camera2.label)
    );

    if (this.availableDevices && this.availableDevices.length > 0) {
      // fetch camera selection
      const currentDevice = this.utilService.getCameraSelection();

      // set first camera on load
      let persistCamera;
      if (currentDevice !== null && currentDevice !== undefined) {
        persistCamera = currentDevice.deviceId;
      } else {
        persistCamera = this.availableDevices[0].deviceId;
      }

      this.onDeviceSelectChange(persistCamera);
    }
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find((x) => x.deviceId === selected);
    this.currentDevice = device || null;
    this.selected = this.currentDevice;

    // set camera selection in service
    this.utilService.setCameraSelection(this.selected);
    this.autoStart = true;

    if (this.currentDevice) {
      this.showScannerDiv = true;
    } else {
      this.showScannerDiv = false;
    }
  }

  init() {
    // we are setting the title of the page to the action selected in the dashboard page
    this.selectedAction = this.utilService.getAction();
    if (
      this.selectedAction &&
      this.selectedAction.hasOwnProperty('key') &&
      this.selectedAction['key'] !== null
    ) {
      // The property parts contains the array from the language file. We are getting the filtered value and assigning the text
      const obj = this.parts.filter(
        (obj) => obj.key.toLowerCase() === this.selectedAction.key.toLowerCase()
      );

      this.title = obj[0].value;
      this.key = obj[0].key;
      // this.title = this.selectedAction.value;
    }

    this.desc = `${this.subtitle} ${this.title}`; //`To start the replacement procedure scan the S/N of the replacement ${this.title}.`;
    this.placeholder = `${this.placeholderText} ${this.title}`; //`Scan S/N of the replacement ${this.title}`;

    if (
      this.key === 'Control PCBA' ||
      this.key === 'Ionizer PSU' ||
      this.key === 'Fan Assembly' ||
      this.key === 'PSU PCBA'
    ) {
      this.allowedFormats = [BarcodeFormat.DATA_MATRIX];
      this.typeOfCode = 'data matrix code';
    } else if (this.key === 'Sensor Module') {
      this.allowedFormats = [BarcodeFormat.CODE_128, BarcodeFormat.QR_CODE];
      this.typeOfCode = 'barcode';
    } else if (this.key === 'RFID PCBA') {
      this.allowedFormats = [BarcodeFormat.DATA_MATRIX];
      this.typeOfCode = 'data matrix code';
    }
  }

  // scanning QR code
  handleQrCodeResult(resultString: string) {
    this.qrResultString = resultString;
    if (this.qrResultString !== null && this.qrResultString.trim() !== '') {
      this.serialNo = this.qrResultString;
      this.serialNoInput.nativeElement.value = this.qrResultString;
      this.displayQRCodeError = false;
      this.displayQRCodeMsg = '';
      this.isDisable = false;
      this.scanner.reset();
    } else {
      this.displayQRCodeError = true;
      this.displayQRCodeMsg = `Scan or enter ${this.typeOfCode}`;
      this.isDisable = true;
    }

    // setTimeout(() => {
    //   this.next();
    // }, 500);
  }

  // manual entry of QR code
  handleManualQRCodeEntry(e) {
    this.handleQrCodeResult(e.target.value);
  }

  next() {
    const sku = this.serialNo.substring(1, 7);
    const revision = this.serialNo.substring(7, 10);

    this.obj = this.utilService.getRepairObj();

    if (this.obj !== undefined) {
      this.obj.replacementpart = {
        sku,
        revision,
        serial: this.serialNo, // serial number of the part
      };
    }

    this.utilService.setRepairObj(this.obj);
    this.scanner.reset();
    // localStorage.setItem('newPartSN', this.serialNo);

    if (this.key === 'Control PCBA') {
      this.router.navigate(['/scan-new-mac']);
    } else {
      this.router.navigate(['/replacement-progress']);
    }
  }

  back() {
    if (this.key === 'Control PCBA') {
      // localStorage.setItem('oldPartMac', '');
      this.router.navigate(['/scan-broken-mac']);
    } else {
      // localStorage.setItem('newPartSN', '');
      this.router.navigate(['/scan-broken-serial']);
    }
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
    this.availableDevices = cameras;
  }

  openInfoCard() {
    this.utilService.openInfoCard();
  }
}
