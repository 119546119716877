import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '../../core/services/util.service';
import { ModalComponent } from '../modal/modal.component';

interface Action {
  name: string;
  value: string;
  key: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  actionControl = new FormControl('', Validators.required);
  actionType: Action[] = [];
  selectedAction = { name: '' };
  selected = { value: '' };

  constructor(
    private utilService: UtilService,
    private router: Router,
    public dialog: MatDialog,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.utilService.checkIfLoggedIn();

    // this.init();
    this.translate.stream('DASHBOARD.PARTS').subscribe((res) => {
      // console.log(res);
      this.actionType = res;
    });
  }

  // init() {
  //   // types of parts in the device
  //   this.actionType = [
  //     { name: 'Control PCBA', value: 'Control PCBA' },
  //     { name: 'Ionizer PSU', value: 'Ionizer PSU' },
  //     { name: 'Fan Assembly', value: 'Fan Assembly' },
  //     { name: 'RFID PCBA', value: 'RFID PCBA' },
  //     { name: 'PSU PCBA', value: 'PSU PCBA' },
  //     { name: 'Sensor Module', value: 'Sensor Module' },
  //   ];
  // }

  // next takes you to the device-serial page
  next() {
    this.selected = this.actionControl.value;
    if (this.selected !== undefined && this.selected.value !== null) {
      this.utilService.setAction(this.selected);
      const dontShowAgain = sessionStorage.getItem('dontShowAgain');
      if (dontShowAgain === 'false' || dontShowAgain === null) {
        // Show Pop-up with information related to lens
        const dialogConfig = new MatDialogConfig();
        const message = this.utilService.getScanInfoMessage();
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          message,
          showCheckBox: true,
        };
        this.dialog
          .open(ModalComponent, dialogConfig)
          .afterClosed()
          .subscribe((res) => {
            this.router.navigate(['/scan-device-serial']);
          });
      } else {
        this.router.navigate(['/scan-device-serial']);
      }
    }
  }
}
