import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { UtilService } from '../../core/services/util.service';
import { ModalComponent } from '../modal/modal.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public myVar = false;
  isLoggedIn: string;
  user: string;
  sessionCounter;
  isSessionExpired = false;
  isTokenExpired;
  userScope;
  selectLang;
  sessionExpiryText = '';

  constructor(
    private utilService: UtilService,
    public dialog: MatDialog,
    private router: Router,
    public translate: TranslateService
  ) {}

  ngOnChanges() {
    this.handleSessionExpiry();
  }

  ngOnInit() {
    const context = this;
    /* tslint:disable */
    window.addEventListener('beforeunload', function (e) {
      context.logout();
    });

    this.getLangFromStorage();
    this.storeLangInStorage(this.selectLang);

    this.translate.stream('SESSION_EXPIRY_TEXT').subscribe((res) => {
      // console.log(res);
      this.sessionExpiryText = res;
    });

    this.init();
  }

  init() {
    this.isLoggedIn = localStorage.getItem('Login');

    this.user = this.utilService.getUsername();

    if (this.user && this.user.indexOf('@') !== -1) {
      this.user = this.user
        .substring(0, this.user.indexOf('@'))
        .replace('.', ' ');
    }

    if (this.isLoggedIn === '1') {
      const secondsInterval = interval(4000);
      this.sessionCounter = secondsInterval
        .pipe(takeWhile(() => !this.isSessionExpired))
        .subscribe((n) => this.handleSessionExpiry());
    }

    this.userScope = this.utilService.getUserScope();
  }

  ngOnDestroy() {
    if (this.sessionCounter) {
      this.isSessionExpired = true;
      this.sessionCounter.unsubscribe();
    }
  }

  // user session management
  handleSessionExpiry() {
    this.isTokenExpired = this.utilService.validateAuthToken(); // returns a boolean value

    if (this.isTokenExpired) {
      this.isSessionExpired = true;
      const message = this.sessionExpiryText; // 'Session has expired. Please login again.';
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        message,
      };
      this.dialog
        .open(ModalComponent, dialogConfig)
        .afterClosed()
        .subscribe((res) => {
          this.logout();
        });
    }
  }

  // on logout
  logout() {
    this.utilService.adminLogout();
  }

  manageUsers() {
    this.router.navigate(['/users']);
  }

  goToDashboard() {
    if (this.isLoggedIn === '1') {
      this.router.navigate(['/dashboard']);
    }
  }

  setLanguage() {
    this.storeLangInStorage(this.selectLang);
    this.translate.use(this.selectLang);
  }

  storeLangInStorage(lang) {
    localStorage.setItem('language', lang);
  }

  getLangFromStorage() {
    const lang = localStorage.getItem('language');
    this.selectLang = lang || this.translate.getDefaultLang();

    this.setLanguage();
  }
}
