import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalComponent } from '../../repairTool/modal/modal.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  appUUID = '';
  deviceID = '';
  usernameUUID = '';
  productData: any;
  accessToken = '';
  action: any;
  objRepair: any;
  helper = new JwtHelperService();
  serial = '';
  userScope: any;
  cameraDevice = null;
  scanInfoMessage = '';
  // 'To carry out scanning of barcodes and data-matrix, ensure you have given permission to camera. If the scanning is not working as expected, then try to change the camera lens and try again.';

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public translate: TranslateService
  ) {}
  // getters and setters

  setUsername(username) {
    this.usernameUUID = username;
  }
  getUsername() {
    return this.usernameUUID;
  }

  setAccessToken(token) {
    this.accessToken = token;
  }
  getAccessToken() {
    return this.accessToken;
  }

  // set selected action
  setAction(obj) {
    this.action = obj;
  }
  getAction() {
    return this.action;
  }

  // set device serial number
  setDevieSN(ds) {
    this.serial = ds;
  }
  getDevieSN() {
    return this.serial;
  }

  // set obj created with all details
  setRepairObj(obj) {
    this.objRepair = obj;
  }

  getRepairObj() {
    return this.objRepair;
  }

  setUserScope(scope) {
    this.userScope = scope;
  }
  getUserScope() {
    return this.userScope;
  }

  // set camera selection
  setCameraSelection(currentDevice) {
    this.cameraDevice = currentDevice;
  }
  getCameraSelection() {
    return this.cameraDevice;
  }

  getScanInfoMessage() {
    this.translate.get('DASHBOARD.POPUP_TEXT').subscribe((res) => {
      // console.log(res);
      this.scanInfoMessage = res;
    });
    return this.scanInfoMessage;
  }
  /**
   * Admin logout will logout user
   */
  adminLogout() {
    this.clearSavedData();
    localStorage.setItem('Login', '0');
    this.router.navigate(['/login']);
  }

  // check if user is logged in
  checkIfLoggedIn() {
    const loginStatus = localStorage.getItem('Login');
    if (loginStatus !== '1') {
      this.adminLogout();
    }
  }

  // clear out localstorage values
  clearSavedData() {
    this.action = '';
    localStorage.setItem('deviceSN', '');
    localStorage.setItem('oldPartSN', '');
    localStorage.setItem('newPartSN', '');
    localStorage.setItem('oldPartMac', '');
    localStorage.setItem('newPartMac', '');
    localStorage.setItem('repairResult', '');
    localStorage.setItem('repairMsg', '');
  }

  // focus on input field
  setInputFocus(inputElement) {
    inputElement.nativeElement.focus();
  }

  // validate authentication token
  validateAuthToken() {
    if (
      this.accessToken &&
      this.accessToken !== '' &&
      this.accessToken !== null
    ) {
      return this.helper.isTokenExpired(this.accessToken); // token expired then return true
    }
  }

  openInfoCard() {
    // console.log(this.getScanInfoMessage());
    const dialogConfig = new MatDialogConfig();
    const message = this.getScanInfoMessage(); // this.scanInfoMessage;
    // 'If the scanning is not working as expected, then try to change the back-camera lens.';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      message,
    };
    this.dialog.open(ModalComponent, dialogConfig);
  }

  isBackCameraLabel(label: string): boolean {
    const lowercaseLabel = label.toLowerCase();
    if (lowercaseLabel && lowercaseLabel.includes('facing')) {
      const backCameraKeywords: string[] = [
        'rear',
        'back',
        'rück',
        'arrière',
        'trasera',
        'trás',
        'traseira',
        'posteriore',
        '后面',
        '後面',
        '背面',
        '后置', // alternative
        '後置', // alternative
        '背置', // alternative
        'задней',
        'الخلفية',
        '후',
        'arka',
        'achterzijde',
        'หลัง',
        'baksidan',
        'bagside',
        'sau',
        'bak',
        'tylny',
        'takakamera',
        'belakang',
        'אחורית',
        'πίσω',
        'spate',
        'hátsó',
        'zadní',
        'darrere',
        'zadná',
        'задня',
        'stražnja',
        'belakang',
        'बैक',
      ];

      return backCameraKeywords.some((keyword) =>
        lowercaseLabel.includes(keyword)
      );
    } else {
      return true;
    }
  }
}
