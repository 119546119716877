import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Result } from '@zxing/library';
import { UtilService } from '../../core/services/util.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-repair-scan-new-mac',
  templateUrl: './repair-scan-new-mac.component.html',
  styleUrls: ['./repair-scan-new-mac.component.scss'],
})
export class RepairScanNewMacComponent implements OnInit {
  @ViewChild('scanner') scanner: ZXingScannerComponent;
  @ViewChild('serialNoInput') serialNoInput: ElementRef;

  title;
  hasDevices = false;
  hasPermission: boolean;
  qrResult: Result;
  availableDevices: MediaDeviceInfo[] = [];
  currentDevice: MediaDeviceInfo = null;
  displayQRCodeError = false;
  displayQRCodeMsg = '';
  qrResultString: any;
  serialNo = '';
  isDisable = true;
  desc = '';
  placeholder = '';
  data: any;
  macId: any;
  tryHarder = true;
  backCameraList = [];
  cameras;
  selected;
  autoStart;
  showScannerDiv = true;
  subtitle = '';
  placeholderText = '';
  wrongMACErrMsg = '';
  noMACErrMsg = '';
  parts = [];
  key = '';

  constructor(
    private utilService: UtilService,
    private router: Router,
    private zone: NgZone,
    public translate: TranslateService
  ) {
    // tslint:disable-next-line: no-string-literal
    window['angularComponentReference'] = {
      zone: this.zone,
      componentFn: (searchcontent: any) =>
        // tslint:disable-next-line: no-string-literal
        window['scannerOutput'](searchcontent),
      component: this,
    };

    this.translate
      .stream([
        'REPLACEMENT_MAC.SUBTITLE',
        'REPLACEMENT_MAC.SCAN_PLACEHOLDER_TEXT',
        'WRONG_MAC',
        'SCAN_MAC',
        'DASHBOARD.PARTS',
      ])
      .subscribe((res) => {
        this.subtitle = res['REPLACEMENT_MAC.SUBTITLE'];
        this.placeholderText = res['REPLACEMENT_MAC.SCAN_PLACEHOLDER_TEXT'];
        this.wrongMACErrMsg = res['WRONG_MAC'];
        this.noMACErrMsg = res['SCAN_MAC'];
        this.parts = res['DASHBOARD.PARTS'];

        this.init();
      });
  }

  ngOnInit() {
    this.utilService.checkIfLoggedIn(); // check if user is logged in

    // to remove expressionchanged error
    // https://github.com/zxing-js/ngx-scanner/issues/270
    this.scanner.reset = function () {
      setTimeout(() => {
        this.deviceChange.emit(null);
      });
    };

    // this.init();
  }

  // In this method, we are trying to get the back cameras available on the mobile device on which this application is served. The library ngx-scanner by default gives us all the audio and video devices available via the cameraDevices parameter. But it is observed that in some mobile devices like Samsung, this doesn't work so we need to use the navigator.enumeratedevices API and get the devices.
  // From the devices available, we are filtering out the back-cameras only since the scanning will be carried out using the back-cameras. We are also trying to set the first camera as the default camera. This is done because in Samsung, by default the wide-lens camera is set as default and scanning doesnt work using the same!
  async onCamerasFound(cameraDevices: MediaDeviceInfo[]) {
    let cameraDeviceList: MediaDeviceInfo[] = [];

    // filter out video only
    this.cameras = cameraDevices.filter(
      (device) => device.kind === 'videoinput'
    );

    // filter out only back cameras
    this.cameras = this.cameras.filter((camera) =>
      this.utilService.isBackCameraLabel(camera.label)
    );

    if (this.cameras.length > 0) {
      cameraDeviceList = this.cameras.slice();
    } else {
      let devices = await navigator.mediaDevices.enumerateDevices();
      let cameras = devices.filter((device) => device.kind === 'videoinput');
      cameras = cameras.filter((camera) =>
        this.utilService.isBackCameraLabel(camera.label)
      );
      cameraDeviceList = cameras.slice();
    }

    this.hasDevices = Boolean(cameraDeviceList && cameraDeviceList.length);

    this.availableDevices = cameraDeviceList.slice(); // this.cameras.slice();

    this.availableDevices = this.availableDevices.sort((camera1, camera2) =>
      camera1.label.localeCompare(camera2.label)
    );

    if (this.availableDevices && this.availableDevices.length > 0) {
      // fetch camera selection
      const currentDevice = this.utilService.getCameraSelection();

      // set first camera on load
      let persistCamera;
      if (currentDevice !== null && currentDevice !== undefined) {
        persistCamera = currentDevice.deviceId;
      } else {
        persistCamera = this.availableDevices[0].deviceId;
      }

      this.onDeviceSelectChange(persistCamera);
    }
  }

  onDeviceSelectChange(selected: string) {
    this.currentDevice = null;

    const device = this.availableDevices.find((x) => x.deviceId === selected);

    this.currentDevice = device || null;

    this.selected = this.currentDevice;

    // set camera selection in service
    this.utilService.setCameraSelection(this.selected);
    this.autoStart = true;

    if (this.currentDevice) {
      this.showScannerDiv = true;
    } else {
      this.showScannerDiv = false;
    }
  }

  init() {
    // we are setting the title of the page to the action selected in the dashboard page
    const selectedAction = this.utilService.getAction();
    // if (selectedAction) {
    //   this.title = selectedAction.value;
    // }

    if (
      selectedAction &&
      selectedAction.hasOwnProperty('key') &&
      selectedAction['key'] !== null
    ) {
      // The property parts contains the array from the language file. We are getting the filtered value and assigning the text
      const obj = this.parts.filter(
        (obj) => obj.key.toLowerCase() === selectedAction.key.toLowerCase()
      );

      this.title = obj[0].value;
      this.key = obj[0].key;
      // this.title = this.selectedAction.value;
    }

    this.desc = `${this.subtitle} ${this.title}`; // this.subtitle; // `To start the replacement procedure scan the MAC of the replacement PCBA.`;
    this.placeholder = `${this.placeholderText} ${this.title}`; //this.placeholderText; // `Scan MAC of the replacement PCBA`;
  }

  // scanning QR code
  handleQrCodeResult(resultString: any) {
    this.qrResultString = resultString;
    if (this.qrResultString !== null && this.qrResultString.trim() !== '') {
      let macId;
      this.serialNo = this.qrResultString;
      if (this.qrResultString.length === 12) {
        macId =
          this.qrResultString.substr(0, 2) +
          ':' +
          this.qrResultString.substr(2, 2) +
          ':' +
          this.qrResultString.substr(4, 2) +
          ':' +
          this.qrResultString.substr(6, 2) +
          ':' +
          this.qrResultString.substr(8, 2) +
          ':' +
          this.qrResultString.substr(10, 2);

        this.macId = macId;
        this.serialNoInput.nativeElement.value = this.qrResultString;

        this.displayQRCodeError = false;
        this.displayQRCodeMsg = '';
        this.isDisable = false;
        this.scanner.reset();
      } else if (this.qrResultString.length === 18) {
        const macStr = this.qrResultString.substring(6);

        macId =
          macStr.substr(0, 2) +
          ':' +
          macStr.substr(2, 2) +
          ':' +
          macStr.substr(4, 2) +
          ':' +
          macStr.substr(6, 2) +
          ':' +
          macStr.substr(8, 2) +
          ':' +
          macStr.substr(10, 2);

        this.macId = macId;
        this.serialNoInput.nativeElement.value = this.qrResultString;

        this.displayQRCodeError = false;
        this.displayQRCodeMsg = '';
        this.isDisable = false;
        this.scanner.reset();
      } else {
        this.displayQRCodeError = true;
        this.displayQRCodeMsg = this.wrongMACErrMsg; // 'Wrong MAC address';
        this.isDisable = true;
      }
    } else {
      this.displayQRCodeError = true;
      this.displayQRCodeMsg = this.noMACErrMsg; // 'Scan or enter MAC address';
      this.isDisable = true;
    }

    // setTimeout(() => {
    //   this.next();
    // }, 500);
  }

  // manual entry of QR code
  handleManualQRCodeEntry(e) {
    this.handleQrCodeResult(e.target.value);
  }

  // on clicking next
  next() {
    this.data = this.utilService.getRepairObj();
    if (this.data !== undefined) {
      this.data.replacementpart.macId = this.macId.toLowerCase();

      this.utilService.setRepairObj(this.data);
      this.scanner.reset();
      // localStorage.setItem('newPartMac', this.serialNo);

      this.router.navigate(['/replacement-progress']);
    }
  }

  back() {
    // localStorage.setItem('newPartMac', '');
    this.router.navigate(['/scan-new-serial']);
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
    this.availableDevices = cameras;
  }

  openInfoCard() {
    this.utilService.openInfoCard();
  }
}
