import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../core/services/auth.service';
import { DataService } from '../../core/services/data.service';
import { UtilService } from '../../core/services/util.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginErrorMsg = '';
  error: any;
  showspinner: boolean;
  loginError = false;

  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    private router: Router,
    public dataService: DataService,
    private utilService: UtilService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    //  loginStatus = 1 - redirect to search
    //  else - redirect to login
    const loginStatus = localStorage.getItem('Login');
    if (loginStatus === '1') {
    } else {
      localStorage.setItem('Login', '0');
    }

    this.loginForm = this.fb.group({
      email: [
        '',
        {
          validators: [Validators.required],
        },
      ],
      password: ['', Validators.required],
    });

    this.utilService.clearSavedData();
  }

  // used in html for checking errors in the input fields.
  get f() {
    return this.loginForm.controls;
  }

  // on clicking submit
  loginFormSubmit() {
    this.loginErrorMsg = '';
    this.loginError = false;

    const username = this.loginForm.controls.email.value.trim();
    const password = this.loginForm.controls.password.value.trim();
    this.spinner.show();
    if (this.loginForm.valid && username !== '' && password !== '') {
      this.authService.signIn(username, password).subscribe(
        (res) => {
          if (res !== '' && res !== null) {
            if (
              res.hasOwnProperty('access_token') &&
              res.access_token !== '' &&
              res.access_token !== null
            ) {
              this.utilService.setAccessToken(res.access_token);
              this.utilService.setUsername(username);

              const req = { uuid: username };

              this.dataService.getUserScope(req).subscribe(
                (response) => {
                  if (
                    response &&
                    response !== null &&
                    response !== undefined &&
                    response.length > 0 &&
                    response[0].hasOwnProperty('scope') &&
                    response[0].scope
                  ) {
                    const scope = response[0].scope;

                    this.utilService.setUserScope(scope);

                    this.loginError = false;
                    this.loginErrorMsg = '';
                    localStorage.setItem('Login', '1');

                    this.router.navigate(['/dashboard']);
                    this.spinner.hide();
                  } else {
                    this.loginError = true;
                    this.loginErrorMsg = 'An unexpected error occurred';
                    this.spinner.hide();
                  }
                },
                (err) => {
                  this.loginError = true;
                  this.loginErrorMsg = 'An unexpected error occurred';
                  this.spinner.hide();
                }
              );
            } else if (
              res.hasOwnProperty('error') &&
              res.error !== '' &&
              res.error != null
            ) {
              this.loginError = true;
              this.loginErrorMsg = res.error;
            } else {
              this.loginError = true;
              this.loginErrorMsg = 'An unexpected error occurred.';
            }
          } else {
            this.loginError = true;
            this.loginErrorMsg = 'An unexpected error occurred.';
          }
        },
        (err) => {
          if (err) {
            if (err.hasOwnProperty('status') && err.status === 0) {
              this.loginErrorMsg =
                'Unable to reach the server. Please try again.';
            } else {
              let errMsgKey;
              if (err.hasOwnProperty('error')) {
                errMsgKey = Object.keys(err.error);
                this.loginErrorMsg =
                  errMsgKey.length > 0
                    ? err.error[errMsgKey[0]]
                    : 'An error occurred during login.';
              }
            }
            this.spinner.hide();
            this.loginError = true;
          }
        }
      );
    } else {
      this.loginError = true;
      this.loginErrorMsg = 'Please enter valid username and password.';
      this.spinner.hide();
    }
  }
}
