import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './repairTool/login/login.component';
import { ForgotPasswordComponent } from './repairTool/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './repairTool/reset-password/reset-password.component';
import { DashboardComponent } from './repairTool/dashboard/dashboard.component';
import { RepairScanDeviceComponent } from './repairTool/repair-scan-device/repair-scan-device.component';
import { RepairScanOldPartComponent } from './repairTool/repair-scan-old-part/repair-scan-old-part.component';
import { RepairScanNewPartComponent } from './repairTool/repair-scan-new-part/repair-scan-new-part.component';
import { RepairProgressComponent } from './repairTool/repair-progress/repair-progress.component';
import { RepairResultComponent } from './repairTool/repair-result/repair-result.component';
import { RepairScanOldMacComponent } from './repairTool/repair-scan-old-mac/repair-scan-old-mac.component';
import { RepairScanNewMacComponent } from './repairTool/repair-scan-new-mac/repair-scan-new-mac.component';
import { UserComponent } from './repairTool/user/user.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'scan-device-serial', component: RepairScanDeviceComponent },
  { path: 'scan-broken-serial', component: RepairScanOldPartComponent },
  { path: 'scan-broken-mac', component: RepairScanOldMacComponent }, // only for PCBA
  { path: 'scan-new-serial', component: RepairScanNewPartComponent },
  { path: 'scan-new-mac', component: RepairScanNewMacComponent }, // only for PCBA
  { path: 'replacement-progress', component: RepairProgressComponent },
  { path: 'replacement-result', component: RepairResultComponent },
  { path: 'users', component: UserComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
