import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTabsModule,
  MatToolbarModule,
  MatCardModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatGridListModule,
  MatCheckboxModule,
  MatProgressBarModule,
} from '@angular/material';

@NgModule({
  imports: [
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatToolbarModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatGridListModule,
    MatCheckboxModule,
    MatProgressBarModule,
  ],
  exports: [
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatToolbarModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatGridListModule,
    MatCheckboxModule,
    MatProgressBarModule,
  ],
})
// @NgModule({
//   declarations: [],
//   imports: [
//     CommonModule
//   ]
// })
export class SharedModule {}
