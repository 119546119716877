import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../core/services/util.service';
import { DataService } from '../../core/services/data.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-repair-progress',
  templateUrl: './repair-progress.component.html',
  styleUrls: ['./repair-progress.component.scss'],
})
export class RepairProgressComponent implements OnInit {
  title = '';
  obj;
  selectedAction;
  parts = [];
  key = '';
  errorMsg = '';
  successMsg = '';

  constructor(
    private utilService: UtilService,
    public dataService: DataService,
    private router: Router,
    public translate: TranslateService
  ) {
    this.translate
      .stream(['DASHBOARD.PARTS', 'UNKNOWN_MSG', 'SUCCESS_MSG'])
      .subscribe((res) => {
        // console.log(res);
        this.parts = res['DASHBOARD.PARTS'];
        this.errorMsg = res['UNKNOWN_MSG'];
        this.successMsg = res['SUCCESS_MSG'];

        this.translateText();
      });
  }

  ngOnInit() {
    this.utilService.checkIfLoggedIn();

    this.init();
  }

  translateText() {
    this.selectedAction = this.utilService.getAction();
    if (
      this.selectedAction !== null &&
      this.selectedAction !== undefined &&
      this.selectedAction.hasOwnProperty('key')
    ) {
      const obj = this.parts.filter(
        (obj) => obj.key.toLowerCase() === this.selectedAction.key.toLowerCase()
      );

      this.title = obj[0].value;
      this.key = obj[0].key;
      // this.title = this.selectedAction.value;
    }
  }

  init() {
    this.obj = this.utilService.getRepairObj();
    const timestamp = Math.floor(new Date().getTime() / 1000);
    if (this.obj !== undefined) {
      this.obj.ts = timestamp;
    }

    if (this.key === 'Control PCBA') {
      this.repairPCBA();
    } else {
      this.processRepair();
    }
  }

  // call API for PCBA
  repairPCBA() {
    this.dataService.repairService(this.obj).subscribe(
      (res) => {
        if (
          res &&
          res !== null &&
          res !== undefined &&
          res.hasOwnProperty('status') &&
          res.status === 'SUCCESS'
        ) {
          this.processRepair();
        } else {
          localStorage.setItem('repairResult', 'false');
          const message = res.message || this.errorMsg;
          localStorage.setItem('repairMsg', message);
          this.router.navigate(['/replacement-result']);
        }
      },
      (err) => {
        const message =
          err.hasOwnProperty('error') && err.error.hasOwnProperty('message')
            ? err.error.message
            : this.errorMsg;
        // const message = 'An unexpected error occurred.';
        localStorage.setItem('repairResult', 'false');
        localStorage.setItem('repairMsg', message);
        this.router.navigate(['/replacement-result']);
      }
    );
  }

  // call API to upload data in S3
  processRepair() {
    let resultMsg = '';
    // this.translate.get('SUCCESS_MSG').subscribe((response) => {
    //   console.log(response);
    //   resultMsg = response;
    // });
    this.dataService.processRepair(this.obj).subscribe(
      (res) => {
        if (
          res &&
          res !== null &&
          res !== undefined &&
          res.hasOwnProperty('status') &&
          res.status === 'SUCCESS'
        ) {
          localStorage.setItem('repairResult', 'true');
          resultMsg = res.message; //this.successMsg;
        } else {
          resultMsg = res.message;
          localStorage.setItem('repairResult', 'false');
        }
        const message = resultMsg || this.errorMsg;
        localStorage.setItem('repairMsg', message);
        this.router.navigate(['/replacement-result']);
      },
      (err) => {
        // const message = err.message || 'An unexpected error occurred.';
        localStorage.setItem('repairResult', 'false');
        localStorage.setItem('repairMsg', this.errorMsg);
        this.router.navigate(['/replacement-result']);
      }
    );
  }
}
