import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public envName = environment.environmentName;

  constructor(private http: HttpClient, private utilService: UtilService) {}

  /**
   * processRepair API - to perform operations on PCBA
   * @param data : object
   */
  processRepair(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.repairURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.repairURL}`;
      const authToken = this.utilService.getAccessToken().toString();
      const httpOptions1 = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          /* prettier-ignore */
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions1);
    }
  }

  /**
   * repair API - to upload file in S3
   * @param data : object
   */
  repairService(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.repairServiceURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.repairServiceURL}`;
      const authToken = this.utilService.getAccessToken().toString();
      const httpOptions1 = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          /* prettier-ignore */
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions1);
    }
  }

  /**
   * get users API
   *
   */
  getUsers(): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.getUsersURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.getUsersURL}`;
      const authToken = this.utilService.getAccessToken().toString();
      const httpOptions1 = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          /* prettier-ignore */
          Authorization: authToken,
        }),
      };

      return this.http.get(url, httpOptions1);
    }
  }

  manageUser(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.manageUserURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.manageUserURL}`;
      const authToken = this.utilService.getAccessToken().toString();

      // const httpOptions = {
      //   headers: new HttpHeaders({
      //     'Content-Type': 'application/json',
      //     Authorization: authToken,
      //   }),
      //   observe: 'response',
      // };
      return this.http.post<any>(url, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
        observe: 'response', // this param allows us to get status code
      });
    }
  }

  /**
   * get user scope API
   * @param uuid
   */
  getUserScope(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.getUserScopeURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}/c/${data.uuid}/scope`;
      const authToken = this.utilService.getAccessToken().toString();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }
}
