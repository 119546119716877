import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  bodyText = '';
  dontShowAgain = false;
  isChecked = false;
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.bodyText = this.data.message;
    this.dontShowAgain = this.data.hasOwnProperty('showCheckBox')
      ? this.data.showCheckBox
      : false;
  }

  // close modal popup
  onNoClick(): void {
    sessionStorage.setItem('dontShowAgain', this.isChecked.toString());

    this.dialogRef.close();
  }
}
