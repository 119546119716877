import { Injectable } from '@angular/core';
import { Observable, TimeoutError, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { timeout, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loginUrl = environment.loginURL;
  constructor(private http: HttpClient) {}

  // api to authenticate user
  signIn(username: string, password: string): Observable<any> {
    let url = environment.loginURL;
    const fullStr = username + ':' + password;
    const encStr = btoa(fullStr);
    const userData = {
      username,
      password,
    };
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + encStr,
      }),
    };

    url =
      url +
      '?client_id=' +
      environment.loginClientId +
      '&client_secret=' +
      environment.loginClientSecret +
      '&scope=' +
      environment.loginScope +
      '&grant_type=' +
      environment.loginGrantType +
      '&audience=' +
      environment.loginAudience;

    return this.http.post(url, {}, httpOptions1);
  }
}
