import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HeaderComponent } from './header/header.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { ModalComponent } from './modal/modal.component';
import {
  MatIconModule,
  MatMenuModule,
  MatSortModule,
  MatPaginatorModule,
} from '@angular/material';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RepairScanDeviceComponent } from './repair-scan-device/repair-scan-device.component';
import { RepairScanOldPartComponent } from './repair-scan-old-part/repair-scan-old-part.component';
import { RepairScanNewPartComponent } from './repair-scan-new-part/repair-scan-new-part.component';
import { RepairProgressComponent } from './repair-progress/repair-progress.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { RepairResultComponent } from './repair-result/repair-result.component';
import { RepairScanOldMacComponent } from './repair-scan-old-mac/repair-scan-old-mac.component';
import { RepairScanNewMacComponent } from './repair-scan-new-mac/repair-scan-new-mac.component';
import { UserComponent } from './user/user.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    HeaderComponent,
    ResetPasswordComponent,
    PasswordStrengthComponent,
    ModalComponent,
    DashboardComponent,
    RepairScanDeviceComponent,
    RepairScanOldPartComponent,
    RepairScanNewPartComponent,
    RepairProgressComponent,
    RepairResultComponent,
    RepairScanOldMacComponent,
    RepairScanNewMacComponent,
    UserComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatSortModule,
    ZXingScannerModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [ModalComponent],
})
export class RepairModule {}
